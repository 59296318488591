// -----------------------------------------------------------------------------
// Footer of the site/application
// -----------------------------------------------------------------------------
.bg__footer {
	background-color: rgb(33, 36, 45);
}
.wrapper__footer {
	padding: 80px 0;
	position: relative;
	@include mobile {
		padding: 40px 0;
	}
	.widget__footer {
		display: block;

		.footer-title {
			text-transform: capitalize;
			margin-bottom: 15px;
			font-family: $text-font-stack;
			color: $white;
			font-size: 18px;
			@include mobile {
				font-size: 16px;
				margin-top: 15px;
			}
		}
		span {
			float: right;
		}

		p {
			font-size: 14px;
			line-height: 1.7;
			margin-bottom: 0;
			color: $gray-500;
		}
		ul {
			li {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				margin-top: 5px;
				font-size: 14px;
				b {
					color: $gray-400;
					margin-right: 5px;
					font-size: 18px;
				}
				span {
					color: $gray-400;
				}
			}
		}

		.link__category-two-column {
			ul {
				-webkit-columns: 2;
				columns: 2;
				-webkit-column-gap: 20px;
				column-gap: 20px;
				margin-bottom: 0;
				@include tablet {
					-webkit-columns: 2;
					columns: 2;
					-webkit-column-gap: 30px;
					column-gap: 30px;
				}
				li {
					margin: 0;
					padding: 0;
					line-height: normal;
					-webkit-column-break-inside: avoid;
					break-inside: avoid;
					display: block;
					a {
						text-transform: capitalize;
						font-size: 14px;
						font-family: $text-font-paragraph;
						line-height: 2;
						color: $gray-500;
						-webkit-transition: 0.3s ease;
						transition: 0.3s ease;
						&:hover {
							color: $warning;
							text-decoration: none;
						}
					}
				}
			}
		}
		.link__category {
			position: relative;
			padding: 15px 0;
			ul {
				-webkit-columns: 3;
				columns: 3;
				-webkit-column-gap: 20px;
				column-gap: 20px;
				margin-bottom: 0;
				@include tablet {
					-webkit-columns: 2;
					columns: 2;
					-webkit-column-gap: 30px;
					column-gap: 30px;
				}
				li {
					margin: 0;
					padding: 0;
					line-height: normal;
					-webkit-column-break-inside: avoid;
					break-inside: avoid;
					display: block;
					a {
						text-transform: capitalize;
						font-size: 14px;
						font-family: $text-font-paragraph;
						line-height: 2;
						color: $gray-500;
						-webkit-transition: 0.3s ease;
						transition: 0.3s ease;
						&:hover {
							color: $warning;
							text-decoration: none;
						}
						@include mobile {
							line-height: 30px;
						}
					}
				}
			}
		}
	}
	.logo-footer {
		width: 152px;
		position: relative;
	}

	figure.image-logo {
		width: 175px;
		img {
			position: relative;
			position: relative;
			width: 100%;
			max-width: 100%;
			height: auto;
			object-fit: cover;
		}
	}
	.social__media {
		text-align: right;
		@include mobile {
			text-align: left;
		}
		.list-inline {
			margin-bottom: 0;
			@include mobile {
				margin-bottom: 15px;
			}
			span {
				font-family: $text-font-paragraph;
				text-transform: capitalize;
				font-size: 11px;
				font-weight: 700;
				margin-right: 10px;
				@include mobile {
					display: block;
					margin-bottom: 10px;
				}
			}
			.list-inline-item {
				.btn-social.whatsapp {
					background-color: #25d366;
				}
				.btn-social.telegram {
					background-color: #179cde;
				}
			}
		}
	}
}

.bg__footer-bottom-v1,
.bg__footer-bottom {
	background-color: #21242d;
	padding: 15px 0;
	border-top: 1px solid rgb(44, 47, 55);
	span {
		color: $gray-400;
		font-size: 12px;
		// font-weight: 600;
		font-family: $text-font-paragraph;
		@include mobile {
			text-align: center;
			display: block;
		}
		a {
			color: $primary;
			text-transform: uppercase;
		}
	}

	.list-inline {
		margin-bottom: 0;
		text-align: right;

		@include mobile {
			text-align: center;
			margin-bottom: 5px;
		}
		.list-inline-item {
			a {
				font-family: $text-font-paragraph;
				color: $gray-400;
				font-size: 12px;
				// font-weight: 600;
				text-transform: capitalize;
				-webkit-transition: 0.3s ease;
				transition: 0.3s ease;
				&:hover {
					color: $warning;
					text-decoration: none;
				}
			}
		}
		.list-inline-item:not(:last-child)::after {
			display: inline-block;
			margin: 0 0 0 6px;
			color: #6c757d;
			font-size: 11px;
			font-weight: 600;
			content: "/";
		}
	}
}

.bg__footer-bottom-v1 {
	background-color: #111111;
	border-top: 1px solid #212121;
}

// SCROLL TO TOP
#return-to-top {
	position: fixed;
	bottom: 30px;
	right: 20px;
	color: $white;
	background: rgba(0, 0, 0, 0.3411764705882353);
	width: 40px;
	height: 40px;
	display: block;
	text-decoration: none;
	text-align: center;
	line-height: 35px;
	z-index: 9;
	transition: all 0.3s ease;
	@include mobile {
		width: 30px;
		height: 30px;
		bottom: 75px;
		line-height: 30px;
	}
}
