// -----------------------------------------------------------------------------
// Products component
// -----------------------------------------------------------------------------
.products__filter {
    border: 1px solid $gray-200;
    .products__filter__group {
        border-bottom: 1px solid $gray-200;
        .products__filter__header {
            padding: 2rem 1rem;
            margin-bottom: 0;
            display: block;

            h5 {
                text-transform: capitalize;
                color: $gray-900;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0;
                line-height: 24px;
                font-family: $font-family-heading;
            }
        }
        &:last-child {
            border-bottom: 0;
        }
        .products__filter__body {
            padding: 2rem 1.5rem 1rem;
            border-top: 1px solid $gray-200;
            transition: all linear 0.25s;

            .form-group {
                display: inline-block;
                width: 100%;
                label {
                    font-family: $font-family-base;
                    font-weight: 500;
                    font-size: 14px;
                }
                .nice-select {
                    border-radius: 0;
                    span {
                        font-family: $font-family-base;
                    }
                    @include mobile {
                        padding-left: 6px;
                        padding-right: 6px;
                    }
                }
                // FILTER PRICE

                .irs--big .irs-handle {
                    border: 1px solid $primary;
                    background-color: $primary;
                    background: $primary;
                    box-shadow: none;
                    width: 20px;
                    height: 25px;
                    border-radius: 0;
                }
                .irs-from {
                    background-color: $primary;
                    background: $primary;
                    &::before {
                        border-top-color: $primary;
                    }
                }
                .irs-to {
                    background-color: $primary;
                    background: $primary;
                    &::before {
                        border-top-color: $primary;
                    }
                }
                .irs--flat .irs-handle > i:first-child {
                    background-color: $primary;
                    margin-left: 0px;
                    width: 5px;
                }
                .irs-bar {
                    background-color: $primary;
                    background: $primary;
                }

                a {
                    font-family: $font-family-base;
                    font-size: 15px;
                    font-weight: 500;
                    &:hover {
                        text-decoration: none;
                    }
                }
                .advancedfilter {
                    position: relative;
                    margin-top: 10px;
                    margin-left: 1px;
                    .checkbox {
                        margin-bottom: 10px;
                        label {
                            &:before,
                            &::after {
                                margin-left: -17px;
                            }
                        }
                    }
                }

                .input-group {
                    &-prepend {
                        .input-group-text {
                            padding: 9px 1rem;
                            border: 1px solid #e9ecef;
                        }
                    }
                }
            }
        }
        .products__filter__footer {
            padding: 1rem;
            border-top: 1px solid $gray-200;
            transition: all linear 0.25s;
        }
    }
}

.nice-select {
    line-height: 42px;
    @include mobile {
        line-height: 40px;
    }
    .list {
        border-radius: 0;
        margin-top: 0;
        max-height: 250px;
        overflow: auto;
        @include mobile {
            max-height: 200px;
        }
    }
    .option {
        font-family: $font-family-base;
    }
    &::after {
        height: 7px;
        width: 7px;
        border-bottom: 2px solid #90a1b5;
        border-right: 2px solid #90a1b5;
        right: 18px;
    }
}

#search__area-1 {
    transition: all 0.4s;
    position: absolute;
    bottom: -85px;
    width: 100%;
    z-index: 2;

    @include mobile {
        position: relative;
        bottom: 0;
    }
    @include tablet {
        position: relative;
        bottom: 0;
    }
}

.bg__shadow {
    border-bottom: 1px solid #e9ecef;
    // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}
.space-area {
    padding-top: 160px;
    @include mobile {
        padding-top: 40px;
    }
    @include tablet {
        padding-top: 40px;
    }
}
.search__area-1 {
    .search__area-inner {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
        padding: 30px 30px 0;
        background-color: $white;
        @include mobile {
            padding: 30px 0 0;
            box-shadow: none;
        }
        @include tablet {
            padding: 30px 0 0;
            box-shadow: none;
        }
    }
}

.search__area {
    transition: all 0.4s;

    width: 100%;
    &-inner {
        padding: 30px 0 0;
        transition: all linear 0.25s;
        @include mobile {
            padding: 30px 0 0;
        }
        .form-group {
            display: inline-block;
            width: 100%;

            // FILTER PRICE

            .irs--big .irs-handle {
                border: 1px solid $primary;
                background-color: $primary;
                background: $primary;
                box-shadow: none;
                width: 20px;
                height: 25px;
                border-radius: 0;
            }
            .irs-from {
                background-color: $primary;
                background: $primary;
                &::before {
                    border-top-color: $primary;
                }
            }
            .irs-to {
                background-color: $primary;
                background: $primary;
                &::before {
                    border-top-color: $primary;
                }
            }
            .irs--flat .irs-handle > i:first-child {
                background-color: $primary;
                margin-left: 0px;
                width: 5px;
            }
            .irs-bar {
                background-color: $primary;
                background: $primary;
            }

            .nice-select {
                border-radius: 0;
                span {
                    font-family: $font-family-base;
                }
                @include mobile {
                    padding-left: 9px;
                    padding-right: 9px;
                    font-size: 13px;
                    &::after {
                        right: 8px;
                    }
                }
            }
        }
    }
}

// CUSTOM COLLAPSE BOOTSTRAP 4 TRANSITION
.collapsing {
    position: relative;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}
.collapsing.width {
    -webkit-transition-property: width, visibility;
    transition-property: width, visibility;
    width: 0;
    height: auto;
}
