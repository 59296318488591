// CAROUSEL
.slider-container {
    .container-slider-image-full {
        img {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100vh;
        }
    }
}

.slider-container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    .banner-max-height {
        img {
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            // position: absolute;
            animation: bgburnsEast 25s linear infinite alternate;
            -webkit-animation: bgburnsEast 25s linear infinite alternate;
            @include mobile {
                height: 450px;
            }
            @include tablet {
                height: 350px;
            }
        }
    }

    .banner__slide-overlay {
        text-shadow: none;
        right: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        bottom: 0;
        background-color: rgba(36, 42, 53, 0.7);
    }
    .carousel-caption {
        @include mobile {
        }
        @include tablet {
        }
        .carousel__content {
            align-self: center !important;
            margin: 0px auto;
            .slider__content-title {
                position: relative;
                @include mobile {
                    top: 15%;
                }
                h2 {
                    font-family: $text-heading;
                    font-size: 60px;
                    margin-bottom: 20px;
                    font-weight: 600;
                    text-transform: capitalize;
                    @include mobile {
                        font-size: 26px;
                        margin-bottom: 15px;
                        line-height: 1.4;
                    }
                    @include tablet {
                        font-size: 27px;
                        margin-bottom: 15px;
                        line-height: 1.4;
                    }
                }
                p {
                    line-height: 1.8;
                    margin-bottom: 35px;
                    @include mobile {
                        font-size: 14px;
                        margin-bottom: 15px;
                    }
                }
                .btn {
                    @include mobile {
                        padding: 10px 15px;
                    }
                }
            }
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        position: absolute;
        top: 0;
        bottom: 0;
    }
    .carousel-control-prev {
        .carousel-control-nav-prev {
            width: 40px;
            height: 40px;
            line-height: 50px;
            position: absolute;
            z-index: 5;
            display: inline-block;
            left: 10px;
            text-align: center;
            background: #a5a5a5;
            border-radius: 5%;
            @include mobile {
                width: 23px;
                height: 45px;
                line-height: 50px;
                left: 0;
                border-radius: 0 5px 5px 0;
            }
        }
    }
    .carousel-control-next {
        .carousel-control-nav-next {
            width: 40px;
            height: 40px;
            line-height: 50px;
            position: absolute;
            z-index: 5;
            display: inline-block;
            right: 10px;
            text-align: center;
            background: #a5a5a5;
            border-radius: 5%;
            @include mobile {
                width: 23px;
                height: 45px;
                line-height: 50px;
                right: 0;
                border-radius: 5px 0 0 5px;
            }
        }
    }
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition: opacity ease-out 0.7s;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
}

.carousel {
    .carousel-control-next,
    .carousel-control-prev {
        visibility: hidden;
        transition: all 300ms ease-out 50ms;
    }
    &:hover {
        .carousel-control-next,
        .carousel-control-prev {
            visibility: visible;
            transition: all 300ms ease-out 50ms;
        }
    }
}

// END CAROUSEL

// HOMEPAGE OWL CAROUSEL
.homepage__property-carousel.owl-carousel {
    .owl-nav {
        margin-top: 0;
        button {
            background: $primary;
            position: absolute;
            text-align: center;
            top: 40%;
            height: 40px;
            width: 40px;
            line-height: 35px;
            font-size: 22px;
            color: #fff;
            outline: none;
            box-shadow: none;
            border-radius: 0;
        }
        button.owl-next {
            right: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        button.owl-prev {
            left: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    .owl-nav.disabled + .owl-dots {
        margin-top: 30px;
    }
}

.homepage__property-carousel:hover.owl-carousel .owl-nav button.owl-next {
    right: 5%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}

.homepage__property-carousel:hover.owl-carousel .owl-nav button.owl-prev {
    left: 5%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}
// END HOMEPAGE OWL CAROUSEL
