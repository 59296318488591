// -----------------------------------------------------------------------------
// SLIDER IMAGE DETAIL
// -----------------------------------------------------------------------------

.slider__image__detail-large {
    .slider__image__detail-large-one {
        position: relative;
        &::after {
            width: 100%;
            height: 40%;
            bottom: 0;
            left: 0;
            position: absolute;
            content: "";
            display: block;
            // background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.49));
            background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 100, 0));
            background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 100, 0));
            background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 100, 0));
        }
        img {
            height: 480px;
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            position: relative;
            @include mobile {
                height: 225px;
            }
        }

        .description {
            width: 100%;
            bottom: 0;
            padding: 0 30px;
            height: 100px;
            position: absolute;
            z-index: 1;
            @include mobile {
                padding: 0 6px;
                height: 100px;
            }
            figure {
                float: left;
                margin-top: 12px;
                img {
                    width: 50px;
                    height: 50px;
                    margin-top: 10px;
                    margin-right: 10px;
                    border-radius: 50%;
                    background-size: cover;
                    @include mobile {
                        width: 35px;
                        height: 35px;
                        margin-right: 4px;
                    }
                }
            }
            span {
                font-family: $font-family-base;
            }
            .price {
                position: relative;
                h5 {
                    color: $white;
                    font-size: 18px;
                    font-family: $font-family-base;
                    letter-spacing: 1px;
                    margin-bottom: 5px;
                    @include mobile {
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
                }
            }
            h4 {
                color: $white;
                font-size: 20px;
                font-family: $font-family-heading;
                @include mobile {
                    font-size: 14px;
                    margin-bottom: 4px;
                }
            }
            p {
                font-size: 14px;
                color: $white;
                @include mobile {
                    font-size: 10px;
                    margin: 0;
                }
            }
        }
    }
}

.slider__image__detail-thumb {
    margin-top: 5px;
    .slider__image__detail-thumb-one {
        background: #c9c9c9;
        color: $white;
        text-align: center;
        cursor: pointer;
        img {
            height: 118px;
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            position: relative;
            @include mobile {
                height: 65px;
            }
        }
        &::after {
            width: 100%;
            height: 40%;
            bottom: 0;
            left: 0;
            position: absolute;
            content: "";
            display: block;
            background: -webkit-gradient(linear-gradient(to top, rgba(0, 0, 0, 0.28) 50%, rgba(0, 0, 100, 0)));
            background: linear-gradient(to top, rgba(0, 0, 0, 0.28) 50%, rgba(0, 0, 100, 0));
        }
        h1 {
            font-size: 18px;
        }
    }
    .current .slider__image__detail-thumb-one {
        background: #0c83e7;
    }
}

.owl-theme {
    .owl-nav {
        /*default owl-theme theme reset .disabled:hover links */
        [class*="owl-"] {
            transition: all 0.3s ease;
            &.disabled:hover {
                background-color: #d6d6d6;
            }
        }
    }
}

//arrows on first carousel
.slider__image__detail-large.owl-carousel {
    .owl-nav {
        margin-top: 0;
        button {
            background: $primary;
            position: absolute;
            text-align: center;
            top: 40%;
            height: 40px;
            width: 40px;
            line-height: 35px;
            font-size: 22px;
            color: $white;
            outline: none;
            box-shadow: none;
            border-radius: 0;
        }
        button.owl-next {
            right: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        button.owl-prev {
            left: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    .owl-nav.disabled + .owl-dots {
        margin-top: 30px;
    }
}

.slider__image__detail-large:hover.owl-carousel .owl-nav button.owl-next {
    right: 3%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}

.slider__image__detail-large:hover.owl-carousel .owl-nav button.owl-prev {
    left: 3%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}

// -----------------------------------------------------------------------------
// SLIDER IMAGE DETAIL STYLE 1
// -----------------------------------------------------------------------------

.slider__property {
    display: flex;
    &-carousel {
        position: relative;

        .item {
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            img {
                height: 540px;
                width: 100%;
                max-width: 100%;
                object-fit: cover;
                @include mobile {
                    height: 250px;
                }
                @include tablet {
                    height: 280px;
                }
            }
        }
    }
}

.slider__property-carousel.owl-carousel {
    .owl-nav {
        margin-top: 0;
        button {
            background: $primary;
            position: absolute;
            text-align: center;
            top: 40%;
            height: 40px;
            width: 40px;
            line-height: 35px;
            font-size: 22px;
            color: $white;
            outline: none;
            box-shadow: none;
            border-radius: 0;
        }
        button.owl-next {
            right: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        button.owl-prev {
            left: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    .owl-nav.disabled + .owl-dots {
        margin-top: 30px;
    }
}

.slider__property-carousel:hover.owl-carousel .owl-nav button.owl-next {
    right: 3%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}

.slider__property-carousel:hover.owl-carousel .owl-nav button.owl-prev {
    left: 3%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}

// -----------------------------------------------------------------------------
// SLIDER IMAGE DETAIL STYLE 4
// -----------------------------------------------------------------------------

.slider__property {
    display: flex;
    &-carousel-large {
        position: relative;

        .item {
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            img {
                height: 600px;
                width: 100%;
                max-width: 100%;
                object-fit: cover;
                position: relative;
                @include mobile {
                    height: 225px;
                }
                @include tablet {
                    height: 450px;
                }
            }
        }
    }
}
.slider__property-carousel-large.owl-carousel {
    .owl-nav {
        margin-top: 0;
        button {
            background: $primary;
            position: absolute;
            text-align: center;
            top: 40%;
            height: 40px;
            width: 40px;
            line-height: 35px;
            font-size: 22px;
            color: $white;
            outline: none;
            box-shadow: none;
            border-radius: 0;
        }
        button.owl-next {
            right: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        button.owl-prev {
            left: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    .owl-nav.disabled + .owl-dots {
        margin-top: 30px;
    }
}

.slider__property-carousel-large:hover.owl-carousel .owl-nav button.owl-next {
    right: 3%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}

.slider__property-carousel-large:hover.owl-carousel .owl-nav button.owl-prev {
    left: 3%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}

// -----------------------------------------------------------------------------
// SLIDER IMAGE DETAIL STYLE 5
// -----------------------------------------------------------------------------

.slider__property {
    display: flex;
    &-carousel-opacity {
        position: relative;
        .owl-item {
            display: block;
            .item {
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                img {
                    height: 600px;
                    width: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    position: relative;
                    @include mobile {
                        height: 200px;
                    }
                    @include tablet {
                        height: 250px;
                    }
                }
            }
        }
    }
}
.slider__property-carousel-opacity.owl-carousel {
    .owl-nav {
        margin-top: 0;
        button {
            background: $primary;
            position: absolute;
            text-align: center;
            top: 40%;
            height: 40px;
            width: 40px;
            line-height: 35px;
            font-size: 22px;
            color: $white;
            outline: none;
            box-shadow: none;
            border-radius: 0;
        }
        button.owl-next {
            right: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        button.owl-prev {
            left: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    .owl-nav.disabled + .owl-dots {
        margin-top: 30px;
    }
}

.slider__property-carousel-opacity:hover.owl-carousel .owl-nav button.owl-next {
    right: 3%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}

.slider__property-carousel-opacity:hover.owl-carousel .owl-nav button.owl-prev {
    left: 3%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}

.slider__property-carousel-opacity .owl-item .item img {
    opacity: 0.1;
    padding: 0px;
    text-align: center;
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    @include mobile {
        opacity: 1;
        transform: none;
    }
}

.slider__property-carousel-opacity .owl-item.active.center .item img {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

// -----------------------------------------------------------------------------
// SLIDER IMAGE DETAIL
// -----------------------------------------------------------------------------

.slider__image__detail-large-two {
    .slider__image__detail-large-one {
        position: relative;
        &::after {
            width: 100%;
            height: 40%;
            bottom: 0;
            left: 0;
            position: absolute;
            content: "";
            display: block;
            // background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.49));
            background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 100, 0));
            background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 100, 0));
            background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 100, 0));
        }
        img {
            height: 640px;
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            position: relative;
            @include mobile {
                height: 225px;
            }
            @include tablet {
                height: 480px;
            }
        }

        .description {
            width: 100%;
            bottom: 0;
            padding: 0 30px;
            height: 100px;
            position: absolute;
            z-index: 1;
            @include mobile {
                padding: 0 6px;
                height: 100px;
            }
            figure {
                float: left;
                margin-top: 12px;
                img {
                    width: 50px;
                    height: 50px;
                    margin-top: 10px;
                    margin-right: 10px;
                    border-radius: 50%;
                    background-size: cover;
                    @include mobile {
                        width: 35px;
                        height: 35px;
                        margin-right: 4px;
                    }
                }
            }
            span {
                font-family: $font-family-base;
            }
            .price {
                position: relative;
                h5 {
                    color: $white;
                    font-size: 18px;
                    font-family: $font-family-base;
                    letter-spacing: 1px;
                    margin-bottom: 5px;
                    @include mobile {
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
                }
            }
            h4 {
                color: $white;
                font-size: 20px;
                font-family: $font-family-heading;
                @include mobile {
                    font-size: 14px;
                    margin-bottom: 4px;
                }
            }
            p {
                font-size: 14px;
                color: $white;
                @include mobile {
                    font-size: 10px;
                    margin: 0;
                }
            }
        }
    }
}

.slider__image__detail-thumb-two {
    margin-top: 5px;
    .slider__image__detail-thumb-one {
        background: #c9c9c9;
        color: $white;
        text-align: center;
        cursor: pointer;
        img {
            height: 118px;
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            position: relative;
            @include mobile {
                height: 55px;
            }
        }
        &::after {
            width: 100%;
            height: 40%;
            bottom: 0;
            left: 0;
            position: absolute;
            content: "";
            display: block;
            background: -webkit-gradient(linear-gradient(to top, rgba(0, 0, 0, 0.28) 50%, rgba(0, 0, 100, 0)));
            background: linear-gradient(to top, rgba(0, 0, 0, 0.28) 50%, rgba(0, 0, 100, 0));
        }
        h1 {
            font-size: 18px;
        }
    }
    .current .slider__image__detail-thumb-one {
        background: #0c83e7;
    }
}

//arrows on first carousel
.slider__image__detail-large-two.owl-carousel {
    .owl-nav {
        margin-top: 0;
        button {
            background: $primary;
            position: absolute;
            text-align: center;
            top: 40%;
            height: 40px;
            width: 40px;
            line-height: 35px;
            font-size: 22px;
            color: $white;
            outline: none;
            box-shadow: none;
            border-radius: 0;
        }
        button.owl-next {
            right: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        button.owl-prev {
            left: 0%;
            visibility: hidden;
            opacity: 0;
            transition: all ease 0.25s;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    .owl-nav.disabled + .owl-dots {
        margin-top: 30px;
    }
}

.slider__image__detail-large-two:hover.owl-carousel .owl-nav button.owl-next {
    right: 3%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}

.slider__image__detail-large-two:hover.owl-carousel .owl-nav button.owl-prev {
    left: 3%;
    visibility: visible;
    opacity: 1;
    transition: all ease 0.25s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    &:hover {
        transition: all ease 0.25s;
        background: $warning;
    }
}
