/* ==========================================================================
   Pricing Table
   ========================================================================== */
.pricing-item {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    background: $white;
    border: solid 3px #ebebeb;
    padding: 0 0 30px 0;
    margin-top: 20px;
    cursor: default;
    z-index: 1;
    border-radius: 2px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    @include tablet {
        margin-top: 30px;
    }
}

.pricing-item:hover {
    -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.15);
}
.pricing-item .pricing-title {
    margin: 0;
    padding: 15px 0;
}
.pricing-item .pricing-price {
    position: relative;
    background: #f1f5f5;
    padding: 40px 0 35px 0;
    margin: 0 0 40px 0;
    font-size: 30px;
    color: #3f3f3f;
    font-weight: 700;
}

.pricing-item .pricing-price:before {
    position: absolute;
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 30px solid #f1f5f5;
    left: 50%;
    bottom: -30px;
    margin-left: -40px;
}
.pricing-item .pricing-feature-list {
    margin: 0 0 25px 0;
    padding: 0;
    list-style: none;
    text-align: center;
    background: $white;
}

.pricing-item .pricing-feature-list li {
    border-bottom: solid 1px #ebebeb;
    padding: 10px 20px 15px 20px;
}

.pricing-item.pricing-column .pricing-feature-list li {
    padding: 15px 20px;
    margin: 0;
    border-bottom: solid 1px #e3e3e5;
    color: #333;
    font-size: 14px;
}

.pricing-item .pricing-period {
    font-size: 14px;
}

.pricing-item.pricing-column .pricing-period {
    font-size: 24px;
    line-height: 24px;
}

.pricing-item .pricing-anim {
    display: inline-block;
    position: relative;
    font-size: 64px;
}

.pricing-item.pricing-column .pricing-anim {
    font-size: 64px;
    text-indent: -10px;
}

.pricing-item:hover .pricing-anim {
    -webkit-animation: moveUp 0.4s forwards;
    animation: moveUp 0.4s forwards;
    -webkit-animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}

.pricing-item:hover .pricing-anim-2 {
    -webkit-animation-delay: 0.05s;
    animation-delay: 0.05s;
}

.pricing-item.active .pricing-price {
    background-color: $primary;
    color: $white;
}
.pricing-item.active .pricing-price:before {
    border-top-color: $primary;
}
.pricing-item.active {
    border-color: $primary;
}
@-webkit-keyframes moveUp {
    50% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    51% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    52% {
        opacity: 1;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes moveUp {
    50% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    51% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    52% {
        opacity: 1;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
