// -----------------------------------------------------------------------------
// CARD
// -----------------------------------------------------------------------------

.cards__icons {
    margin-top: 30px;
    padding: 40px 30px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
        .cards__icons-header-hover {
            background-color: $primary;
        }
    }
    &-left {
        border: 1px solid gray;
    }
    &-header {
        position: relative;
        &-hover {
            background-color: #a7d1ff;

            height: 130px;
            line-height: 130px;
            margin: 0 auto;
            width: 130px;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
        .circle {
            border-radius: 50%;
        }
    }
    &-footer {
        padding-top: 30px;
        h5 {
            font-family: $text-heading;
        }
        p {
            line-height: 1.5;
            margin-bottom: 0;
        }
    }
}

// -----------------------------------------------------------------------------
// CARD STLYE 1
// -----------------------------------------------------------------------------

.cards__icons-style-1 {
    margin-bottom: 30px;
    // padding: 50px 41px 30px 41px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    // border: 1px solid #e9ecef;

    &:hover {
        cursor: pointer;
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
        .cards__icons-header-hover {
            background-color: $primary;
        }
    }
    .cards__icons-header-left {
        text-align: left;
    }

    &-footer {
        padding-top: 30px;
        h5 {
            font-family: $text-heading;
        }
        p {
            line-height: 1.5;
            margin-bottom: 0;
        }
    }
}
