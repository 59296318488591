// -----------------------------------------------------------------------------
// BLOG
// -----------------------------------------------------------------------------
.blog__grid {
    margin-top: 30px;
}
// WIDGET
.widget__sidebar {
    border: 1px solid #e9ecef;
    margin-top: 30px;
    &__header {
        padding: 1rem 1.5rem;
        margin-bottom: 0;
        display: block;
        h6 {
            margin-bottom: 0;
            font-family: $font-family-heading;
        }
    }
    &__body {
        padding: 1.5rem;
        border-top: 1px solid #e9ecef;
        transition: all linear 0.25s;
        .list-unstyled {
            margin: 0;
            padding: 0;
            li {
                padding: 0 0 15px;
                position: relative;

                &:last-child {
                    padding: 0;
                }
                a {
                    font-size: 14px;
                    display: block;
                    text-decoration: none;
                    color: $navy;
                    text-transform: capitalize;
                    letter-spacing: 0.3px;
                    transition: all 0.3s ease;
                    font-family: $font-family-base;
                    &:hover {
                        color: $primary;
                        padding-left: 8px;
                    }
                    span {
                        float: right;
                        position: relative;
                        font-size: 14px;
                        background-color: #c1d0ff;
                        color: $primary;
                        font-weight: 600;
                        line-height: 15px;
                        border-radius: 3px;
                        height: 22px;
                    }
                }
            }
        }
        &-img {
            position: relative;
            display: inline-block;
            margin-top: 20px;
            &:first-child {
                margin-top: 0;
            }
            img {
                width: 35%;
                max-width: 100%;
                height: auto;
                object-fit: cover;
                float: left;
                margin-right: 5px;
                position: relative;
            }
        }
        span {
            font-family: $font-family-base;
        }
        &-heading {
            display: flex;
            overflow: hidden;
            h6 {
                font-family: $font-family-heading;
                line-height: 1.4;
                font-size: 14px;
                margin-bottom: 0;
                a {
                    color: $navy;
                    transition: all 0.3s ease;
                    &:hover {
                        color: $primary;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    &__footer {
        padding: 1rem;
        border-top: 1px solid #e9ecef;
        transition: all linear 0.25s;
    }
    .btn-search {
        background: #ffffff;
        color: #333333;
        height: 45px;
        box-shadow: none;
        border-radius: 0px;
        border-top: solid 1px #eeeeee;
        border-bottom: solid 1px #eeeeee;
        border-right: solid 1px #eeeeee;
        border-left: solid 1px #eeeeee;
        margin: 0 -1px 0;
    }
}

// TAGS

.blog__tags {
    display: inline-block;
    .list-inline {
        margin: 0;
        padding: 0;

        &-item {
            margin-right: 0;
            position: relative;
            a {
                display: block;
                margin-bottom: 5px;
                padding: 7px 10px;
                border: 1px solid #ddd;
                margin-right: 2px;
                color: $navy;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 1px;
                transition: all linear 0.25s;
                &:hover {
                    background-color: $primary;
                    color: $white;
                    border: 1px solid $primary;
                }
            }
        }
    }
}
