// -----------------------------------------------------------------------------
// AGENTS
// -----------------------------------------------------------------------------
.profile__agency {
    position: relative;
    box-sizing: border-box;
    transition: all 0.4s;
    &-logo {
        position: relative;
        border-right: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 300px;
        text-align: center;
        @include mobile {
            border-right: 0;
            border-bottom: 1px solid #eee;
        }
        img {
            // height: 311px;
            // width: 100%;
            object-fit: cover;
        }
    }
    &-info {
        display: block;
        @include mobile {
            padding: 20px;
        }
        h5 {
            font-family: $font-family-heading;
            font-size: 18px;
            a {
                transition: all 0.4s;
                color: $black;
                &:hover {
                    color: $primary;
                    text-decoration: none;
                }
            }
        }

        ul {
            padding: 0;
            margin: 0;
            li {
                margin-bottom: 10px;
                a {
                    font-size: 15px;
                    color: $black;

                    font-weight: 500;
                    span {
                        width: 100px;
                        display: inline-block;

                        i.fa {
                            text-align: center;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            margin-right: 5px;
                            color: $primary;
                            background-color: #c1d0ff;
                        }
                    }
                    &:hover {
                        text-decoration: none;
                        color: $primary;
                    }
                }
            }
        }
    }
}

.profile__agency {
    .col-lg-12 {
        position: relative;
        border: 1px solid #eee;
        box-sizing: border-box;
        transition: all 0.4s;
        height: 100%;
        margin-top: 30px;
        &:hover {
            border: 1px solid rgba(0, 0, 0, 0.15);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
        }
    }
    .cards {
        position: relative;
        border: 1px solid #eee;
        box-sizing: border-box;
        transition: all 0.4s;
        margin-top: 30px;
        &:hover {
            border: 1px solid rgba(0, 0, 0, 0.15);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
        }
        .profile__agency-header {
            border-bottom: 1px solid #eee;
            .profile__agency-logo {
                border: 0;
                min-height: 240px;
            }
        }
        .profile__agency-body {
            padding: 20px;
            @include mobile {
                padding: 0;
            }
        }
    }
}

.total__property-agency {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 3px 6px;
    background-color: #002247;
    box-shadow: 1px 2px 1px rgba(94, 93, 99, 0.08), 0 1px 2px rgba(61, 60, 66, 0.12);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    color: $white;
    font-family: $font-family-base;
}
