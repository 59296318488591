// -----------------------------------------------------------------------------
// SINGLE DETAIL
// -----------------------------------------------------------------------------
// .single__Detail {
//     .container {
//         max-width: 1560px;
//     }
// }
.single__detail-area {
    padding: 40px 0;

    &-title {
        position: relative;
        h3 {
            font-family: $font-family-heading;

            @include mobile {
                font-size: 18px;
            }
        }
        p {
            margin-bottom: 0;
        }
    }
    &-price {
        position: relative;
        text-align: right;
        @include mobile {
            text-align: left;
            margin-top: 15px;
        }
        h3 {
            @include mobile {
                font-size: 18px;
                float: left;
            }
        }

        .list-inline {
            position: relative;
            margin: 0;
            @include mobile {
                text-align: right;
            }
        }
    }
}

.single__detail {
    &-title {
        h3 {
            font-family: $font-family-heading;

            @include mobile {
                font-size: 18px;
            }
        }
        p {
            @include mobile {
                margin-bottom: 0;
                font-size: 14px;
            }
        }
    }
    &-price {
        text-align: right;
        h3 {
            @include mobile {
                font-size: 18px;
                float: left;
            }
        }
        @include mobile {
            text-align: left;
        }
        .list-inline {
            position: relative;
            @include mobile {
                text-align: right;
            }
        }
    }
    &-desc {
        h5 {
            font-family: $font-family-heading;
            @include mobile {
                font-size: 16px;
            }
        }
        p {
            line-height: 1.9;
        }

        .show__more {
            max-height: 240px;
            overflow: hidden;
            position: relative;
            transition: max-height 1s;
        }
        a.show__more-button {
            background: $primary;
            color: $white;
            height: 46px;
            border-radius: 4px !important;
            bottom: 0;
        }
        .show__more:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 180px;
            display: block;
            background: linear-gradient(rgba(255, 255, 255, 0), #fff 88%);
            z-index: 1;
            opacity: 1;
            visibility: visible;
            transition: all 1s;
        }
        .show__more.visible {
            max-height: 2000px;
        }
        .show__more.visible:after,
        .show__more.visible .show__more-button {
            opacity: 0;
            visibility: hidden;
        }
        .show__more-button {
            position: absolute;
            bottom: -15px;
            left: 0;
            margin-left: 50%;
            transform: translateX(-50.5%);
            z-index: 2;
            text-align: center;
            display: block;
            opacity: 1;
            min-width: 200px;
            visibility: visible;
            transition: all 0.3s;
            padding: 10px 20px;
            text-transform: capitalize;
            font-family: $font-family-base;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &-features {
        h5 {
            font-family: $font-family-heading;
            font-size: 18px;
        }

        .list-unstyled.icon-checkbox {
            -webkit-columns: 3;
            -moz-columns: 3;
            columns: 3;
            -webkit-column-gap: 20px;
            -moz-column-gap: 20px;
            column-gap: 20px;
            margin-bottom: 0;
            @include mobile {
                columns: 1;
                -webkit-columns: 1;
                -moz-columns: 1;
            }
            @include tablet {
                -webkit-columns: 2;
                -moz-columns: 2;
                columns: 2;
            }
            li {
                line-height: normal;
                -webkit-column-break-inside: avoid;
                -moz-column-break-inside: avoid;
                break-inside: avoid;
                display: block;
                padding: 8px 0;
                font-family: $font-family-base;
                text-transform: capitalize;
                position: relative;
                font-size: 14px;
                &::before {
                    display: inline-block;
                    width: 19px;
                    height: 19px;
                    margin-right: 10px;
                    top: -1px;
                    position: relative;
                    content: "\f00c";
                    font-family: "FontAwesome";
                    font-size: 12px;
                    color: $primary;
                    text-align: center;
                    line-height: 15px;
                    border: 2px solid transparent;
                    transition: border-color 0s;
                    border-radius: 2px;
                }
            }
        }
        .list-unstyled.icon-checkbox {
            li {
                &::before {
                    background-color: #c1d0ff;
                }
            }
        }
        &-title {
            border: 1px solid gray;
            h5 {
                font-family: $font-family-heading;
            }
        }
        &-video {
            position: relative;
            border: 1px solid #eaeaea;
            padding: 6px;
        }
        &-nearby {
            position: relative;
            h6 {
                span {
                    background: #c1d0ff;
                    width: 25px;
                    height: 25px;
                    display: inline-block;
                    line-height: 25px;
                    border-radius: 3px;
                    text-align: center;
                    margin-right: 7px;
                    i.fa {
                        color: $primary;
                    }
                }
            }
            .list-unstyled {
                padding: 0;
                margin: 15px 0 20px 0;
                li {
                    padding: 4px 0;
                    span {
                        float: left;
                        font-family: $font-family-base;
                        color: $gray-700;
                        font-size: 14px;
                    }
                    p {
                        margin-bottom: 0;
                        text-align: right;
                        color: $primary;
                        &::before {
                            display: inline-block;
                            width: 19px;
                            height: 19px;
                            margin-right: 5px;
                            top: -1px;
                            position: relative;
                            content: "\f041";
                            font-family: "FontAwesome";
                            font-size: 14px;
                            color: #3454d1;
                            text-align: center;
                            line-height: 15px;
                            border: 2px solid transparent;
                            -webkit-transition: border-color 0s;
                            transition: border-color 0s;
                            border-radius: 2px;
                        }
                    }
                }
            }
        }
        &-review {
            position: relative;
            display: inline-block;
            @include mobile {
                margin-bottom: 30px;
            }
            .selected {
                color: $primary;
            }
            i.fa {
                font-size: 18px;
            }
            .form-group {
                label {
                    font-family: $font-family-base;
                }
            }
            .media {
                img {
                    position: relative;
                    width: 60px;
                    object-fit: cover;
                    height: auto;
                    max-width: 100%;
                }
                .media-body {
                    span {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 30px;
                        font-family: $font-family-base;
                    }
                    h6 {
                        margin: 0;
                    }
                    p {
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

.detail-heading {
    margin: 20px 0 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
    font-family: $font-family-heading;
}

.property__detail-info {
    h6 {
        font-family: $font-family-heading;
        margin: 15px 0;
    }
    &-list {
        position: relative;
        li {
            margin-bottom: 8px;
            font-size: 14px;
            font-family: $font-family-base;
            b {
                font-size: 14px;
                margin-right: 3px;
            }
        }
    }
}
