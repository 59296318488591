// -----------------------------------------------------------------------------
// HERO
// -----------------------------------------------------------------------------

.hero {
	position: relative;
	background-color: #d1e6f9;
	height: 100vh;
	min-height: 25rem;
	width: 100%;
	overflow: hidden;
	video {
		position: absolute;
		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		z-index: 0;
		-ms-transform: translateX(-50%) translateY(-50%);
		-moz-transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}
	.container {
		position: relative;
		z-index: 2;
	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: black;
		opacity: 0.5;
		z-index: 1;
	}
}

@media (pointer: coarse) and (hover: none) {
	.hero {
		// background: url("https://source.unsplash.com/XT5OInaElMw/1600x900") black no-repeat center center scroll;
	}
	.hero video {
		display: none;
	}
}
