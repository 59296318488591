.canvas {
    position: relative;
    width: 100%;
}

.note {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 25px;
}
