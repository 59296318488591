// -----------------------------------------------------------------------------
// TESTIMONIAL
// -----------------------------------------------------------------------------
.testimonial {
    padding: 0 10px;
}
.testimonial__block {
    display: block;
    // margin-top: 30px;
    @include mobile {
        margin-top: 15px;
    }

    &-card {
        padding: 2rem;
        border-radius: 4px;
        border: 1px solid #f4f4f4;
        background-color: $white;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=32, Direction=37, Color=#eee)";
        box-shadow: -4px 3px 32px -2px #eee;
        filter: progid:DXImageTransform.Microsoft.Shadow(Strength=32, Direction=135, Color=#eee);
        @include tablet {
            padding: 1.2rem;
        }
        &::before {
            font-family: FontAwesome;
            content: "\f10d";
            display: block;
            color: #e5e5e5;
            position: absolute;
            left: 4%;
            top: 9%;
            font-size: 24px;
            z-index: -1;
            @include mobile {
                left: 7%;
                top: 10%;
            }
        }
        p {
            color: $gray-900;
        }
    }
    &-users {
        position: relative;
        margin-top: -30px;
        padding-left: 30px;
        @include mobile {
            padding-left: 15px;
        }
        @include tablet {
            padding-left: 15px;
        }
        .testimonial__block-users-img {
            display: inline-block;
            img {
                position: relative;
                width: 75px;
                height: 75px;
                border-radius: 50%;
                max-width: 100%;
                object-fit: cover;
            }
        }
        .testimonial__block-users-name {
            float: right;
            background: $white;
            padding: 10px 20px;
            border-radius: 3px;
            // margin-top: 10px;
            border: 1px solid #f4f4f4;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            margin-right: 30px;
            color: $primary;
            font-weight: 700;
            font-size: 14px;
            text-transform: capitalize;
            font-family: $font-family-base;

            -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=29, Direction=99, Color=#e8e8e8)"; /*IE 8*/
            -moz-box-shadow: 3px 18px 29px -18px #e8e8e8; /*FF 3.5+*/
            -webkit-box-shadow: 3px 18px 29px -18px #e8e8e8; /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
            box-shadow: 3px 18px 29px -18px #e8e8e8; /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
            filter: progid:DXImageTransform.Microsoft.Shadow(Strength=29, Direction=135, Color=#e8e8e8); /*IE 5.5-7*/
            @include mobile {
                margin-right: 15px;
            }
            @include tablet {
                margin-right: 15px;
            }
            span {
                color: $gray-700;
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
}
