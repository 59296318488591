.block-quote {
    background-color: #fafafa;
    padding: 30px 30px 30px 70px !important;
    line-height: 25px;
    font-weight: 500;
    position: relative;
    margin: 20px 0;
    &::before {
        font-family: FontAwesome;
        content: "\f10d";
        position: absolute;
        left: 30px;
        top: 35px;
        color: $primary;
        font-size: 25px;
        font-style: normal;
    }
}
