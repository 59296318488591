.search__property {
    // border: 1px solid gray;
    .position-relative {
        .nav-tabs-02 {
            border: 0;
            .nav-item {
                .nav-link {
                    background: transparent;
                    color: $white;
                    padding: 10px 20px;
                    line-height: 24px;
                    border: 0;
                    font-family: $font-family-base;
                    text-transform: capitalize;
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 0.5px;
                    border-radius: 4px;
                }
                .nav-link.active {
                    background: $primary;
                    color: $white;
                }
            }
            .search__container {
                input {
                    border: 1px solid #e9ecef;
                }
            }
        }
    }
}
