// -----------------------------------------------------------------------------
// AGENT / TEAM
// -----------------------------------------------------------------------------
.our__team {
    h2 {
        font-family: $font-family-heading;
    }
}
.wrap-agent {
    .title-agent {
        padding-bottom: 50px;
        h3 {
            text-align: center;
            margin: 0 0px 20px 0;
        }
        p {
            text-align: center;
        }
    }
    .team-member {
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        position: relative;
        .team-img {
            position: relative;
            img {
                height: 300px;
                object-fit: cover;
            }
        }
        &:hover {
            .team-hover {
                .desk {
                    top: 35%;
                    opacity: 1;
                }
                opacity: 1;

                .s-link {
                    opacity: 1;
                    bottom: 10%;
                }
            }
        }
        .s-link {
            a {
                margin: 0 10px;
                font-size: 16px;
            }
        }
    }
    .team-hover {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0;
        border: 20px solid rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.9);
        opacity: 0;
        transition: all 0.3s;
        .desk {
            position: absolute;
            top: 15%;
            width: 100%;
            opacity: 0;
            -webkit-transform: translateY(-55%);
            transform: translateY(-55%);
            transition: all 0.3s 0.2s;
            padding: 0 20px;
        }
        .list-inline {
            position: absolute;
            bottom: 0;
            width: 100%;
            opacity: 0;
            text-align: center;
            -webkit-transform: translateY(45%);
            transform: translateY(45%);
            transition: all 0.3s 0.2s;
            font-size: 35px;
            &-item {
                a {
                    text-align: center;
                    color: $navy;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }
    .desk {
        text-align: center;

        h5 {
            text-align: center;
            font-family: $text-heading;
        }
    }
    .team-title {
        position: static;
        padding: 20px;
        display: inline-block;
        width: 100%;
        text-align: center;
        border: 1px solid #e9ecef;
        h6 {
            margin-bottom: 0;
            display: block;
            text-transform: capitalize;
        }
        span {
            font-size: 14px;
            text-transform: capitalize;
            letter-spacing: 1px;
        }
    }
}

// -----------------------------------------------------------------------------
// PROFILE AGENTS
// -----------------------------------------------------------------------------
.profile__agent {
    border: 1px solid $gray-200;
    @include mobile {
        margin-top: 20px;
    }
    .profile__agent__group {
        border-bottom: 1px solid $gray-200;

        .profile__agent__header {
            padding: 1rem;
            margin-bottom: 0;
            display: block;
            img {
                position: relative;
                border-radius: 50%;
            }
            h5 {
                text-transform: capitalize;
                color: $gray-900;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0;
                line-height: 24px;
                font-family: $font-family-heading;
                margin-bottom: 10px;
            }
            &-avatar {
                display: flex;
                figure {
                    float: left;
                    margin-bottom: 0;
                    img {
                        width: 90px;
                        height: 90px;
                        position: relative;
                    }
                }
                .list-unstyled {
                    margin-left: 15px;
                    li {
                        margin-bottom: 5px;
                        a {
                            font-family: $font-family-base;
                            font-size: 14px;
                            font-weight: 500;
                            color: $navy;
                            i.fa {
                                font-size: 15px;
                            }
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
        &:last-child {
            border-bottom: 0;
        }
        .profile__agent__body {
            padding: 1rem;
            border-top: 1px solid $gray-200;
            transition: all linear 0.25s;
        }
        .profile__agent__footer {
            padding: 1rem;
            border-top: 1px solid $gray-200;
            transition: all linear 0.25s;
        }
    }
}

// -----------------------------------------------------------------------------
// AGENTS
// -----------------------------------------------------------------------------
.profile__agents {
    position: relative;
    box-sizing: border-box;
    transition: all 0.4s;
    &-avatar {
        position: relative;
        display: block;
        img {
            height: 311px;
            width: 100%;
            object-fit: cover;
        }
    }
    &-info {
        display: block;
        @include mobile {
            padding: 20px;
        }
        h5 {
            font-family: $font-family-heading;
            font-size: 18px;
            a {
                color: $black;
                &:hover {
                    text-decoration: none;
                    color: $primary;
                }
            }
        }

        ul {
            padding: 0;
            margin: 0;
            li {
                margin-bottom: 10px;
                a {
                    color: $navy;
                    font-size: 14px;
                    span {
                        width: 100px;
                        display: inline-block;

                        i.fa {
                            text-align: center;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            margin-right: 5px;
                            color: $primary;
                            background-color: #c1d0ff;
                        }
                    }
                    &:hover {
                        text-decoration: none;
                        color: $primary;
                    }
                }
            }
        }
    }
}

.profile__agents {
    .cards {
        position: relative;
        border: 1px solid #eee;
        box-sizing: border-box;
        transition: all 0.4s;

        margin-top: 30px;
        &:hover {
            border: 1px solid rgba(0, 0, 0, 0.15);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
        }

        .profile__agents-body {
            padding: 20px;
            @include mobile {
                padding: 0;
            }
        }
    }
}

.total__property-agent {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 3px 6px;
    background-color: #002247;
    box-shadow: 1px 2px 1px rgba(94, 93, 99, 0.08), 0 1px 2px rgba(61, 60, 66, 0.12);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    color: $white;
    font-family: $font-family-base;
}
