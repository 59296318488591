// -----------------------------------------------------------------------------
// Basic styles
// -----------------------------------------------------------------------------

// example:
// https://github.com/HugoGiraudel/sass-boilerplate/blob/master/stylesheets/base/_base.scss

html,
body {
  height: 100%;
}
