// -----------------------------------------------------------------------------
// Contact page
// -----------------------------------------------------------------------------
.wrap__contact-open-hours {
    display: block;
    h5 {
        font-family: $font-family-heading;
        font-size: 18px;
    }
    .list-unstyled {
        li {
            font-size: 14px;
            border-bottom: 1px solid #eee;
            padding: 7px 0;
            font-weight: 500;
            @include mobile {
                padding: 6px;
            }
            span {
                font-family: $font-family-base;
            }
        }
    }
}

.wrap__contact-form {
    display: block;

    h5 {
        font-size: 18px;
        text-transform: capitalize;
        margin-bottom: 30px;
        font-family: $font-family-heading;
        @include mobile {
            margin-bottom: 25px;
        }
    }
    .form-group {
        label {
            font-family: $text-font-paragraph;
            font-weight: 600;
        }
        input {
            font-family: $text-font-paragraph;
            text-transform: capitalize;
        }
        .btn-contact {
            @include mobile {
                margin-top: none;
            }
        }
    }

    .wrap__contact-form-office {
        display: block;
        .list-unstyled {
            li {
                font-size: 14px;
                display: flex;
                margin-bottom: 15px;
                font-family: $text-font-paragraph;
                font-weight: 500;
                span {
                    display: block;
                    line-height: 35px;

                    i.fa {
                        float: left;
                        border: 1px solid #dbe1e8;
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                        font-size: 18px;
                        margin-right: 15px;
                    }
                    a {
                        color: $black;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .social__media {
            .list-inline {
                padding: 0;
                margin: 0;
                box-sizing: border-box;
            }
        }
    }
}
